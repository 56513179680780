@import "../../../../../../style/variables.scss";

.container_new_create_properties {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border: 1px solid $gray;
    border-radius: $border_radius;
}

.container_create_properties__title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
    margin-bottom: 8px;
}

.container_hide {
    display: none;
}