.container_properties_title {
    width: 100%;
    padding: 0 8px 8px 8px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.container_element_inputs__two {
    display: flex;
    gap: 16px;
}

.container_properties_cards {
    margin: 24px 0;
}

