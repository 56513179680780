.input_password {
    width: 100%;
}

.icon-eyes {
    border: none !important;
}

.icon-eyes svg {
    width: 20px;
}

.inputPassword {
    input {
        border-right: none !important;
    }
}

.icon-eyes {
    border: 1px solid #dee2e6 !important;
    border-left: none !important;
}