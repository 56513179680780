@import "../../style/variables.scss";

.sidebar {
    padding: 54px 0 0;
    background-color: $blue_dark !important;
    min-width: 235px !important;
    width: 235px !important;
    max-width: 235px !important;
}

.sidebar.nav {
    margin-left: -15px;
}

.container_sameicon {
    min-width: 65px !important;
    width: 65px !important;
    max-width: 65px !important;
}

.sameicon {
    display: none;
}

.cta_sidebar {
    position: absolute;
    padding: 8px;
    top: 46px;
    background:  $blue_dark !important;
    border-radius: 0 4px 4px 0;
    
    &:hover {
        cursor: pointer;
    }
    
    svg {
        color: $white !important;
    }
}

.cta_expand {
    left: 62px;
}

.cta_minus {
    left: 232px;
    z-index: 1;
}

.nav-link {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    line-height: 15px;
    border-bottom: 2px solid $gray_lite;
    color: $white;
    padding: 12px 24px;
    border-radius: $border_radius;
    font-size: 15px;
    font-weight: 600;

    svg {
        color: $white !important;
        width: 22px;
    }

    &:hover {
        background: $secondary;
        color: $black !important;

        svg {
            color: $black !important; 
        }
    }

    &:active, &:focus{
        background: $orange_lite !important;
        color: $text_color !important;

        svg {
            color: $text_color !important; 
        }
    }
}

.active_item_menu {
    background: $orange_lite !important;
    color: $black !important;

    svg {
        color: $black !important;
    }

    &:hover {
        background: $orange_lite !important;
        color: $black !important;

        svg {
            color: $black !important;
        }
    }
}