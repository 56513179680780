@import "./style/variables.scss";

html, body {
    height: 100%;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    overflow-y: hidden;
    color: $text_color;
}

#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.form-label {
    margin-bottom: 2px;
}
.container-fluid {
    background: $background-color;
    height: 100%;
    width: 100%;
    padding: none !important;
}

//
.container-root {
    display: flex;
    // gap: 30px;
    padding-right: 0 !important;

    @media (max-width: 768px) {
        padding-left: 0 !important;
    }
}


.notnavsidebar {
   height: 100%;
}
.navsidebar {
    padding-right: 60px;
    padding-left: 0;
}
section {
    padding: 0 !important;
}

h1, h2, h3, h4, h5 {
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

h1 {
    font-size: 24px;
    color: $secondary;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 16px;
}


.row {
    @media screen and (max-width: 767px) {
        margin: 0 8px;
    }
}
.header {
    display: flex;
    width: 100%;
    padding: 8px 0px;
    justify-content: space-between;
    align-items: center;
    margin: 85px 0 16px;
}


.container-panel {
    display: flex;
    width: 100%;
    min-width: 150px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid $gray;
    background: $background-color;
    box-shadow: $box-shadow;
    margin-bottom: 24px;
}

.container-panel-input {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;

    div{
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.container-panel-select {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;

    .texterror {
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.container-panel-date {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    justify-content: flex-start;

    .input {
        width: auto;
    }

    div {
        width: 200px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;

        .input {
            width: 100%;
        }

        div {
            width: 100%;
        }
    }
}

.container-panel-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.container-alerts {
    width: 100%;
}

.btn-outline-primary {
    border-color: $primary;
    color: $primary;
    &:hover, &:active, &:focus{
        background-color: $primary !important;
        border: $primary !important;
        color: $white;
    }
}

input[type="date"] { 
    width: 200px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

input:read-only {
    background-color: $gray_lite;  
    color: #6c757d;             
    cursor: not-allowed;  
}

.btn-primary {
    background: $primary !important;
    border-color: $primary !important;
}

.container_panel_flex_center{
    height: 100%;
    background: $background-color !important;
    justify-content: center;
    align-items: center;
}

.container_triangle svg {
    position: absolute;
    right: 0;
    bottom: 0;

    @media screen and (max-width: 767px) {
        width: 180px;
        z-index: -1;
    }
}

.texterror {
    color: #EC1C24;
    font-size: 13px;
    margin-bottom: -16px;
}

.form-check-input {
    cursor: pointer !important;
}

//Tabela
.rdt_TableHead {
    font-family: 'Nunito' !important;
    font-size: 14px !important;
    font-weight: bold !important;
}
.rdt_TableRow {
    font-family: 'Nunito' !important;
    font-size: 14px !important;
    svg {
        width: 22px !important;
    }
}



.container-custom-fluid {
    width: 100% !important;
    max-width: none;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    // padding-right: 24px;
    overflow-y: auto;
    background: $background-color;

    @media (max-width: 799px) {    
        // padding-right: 15px;       
        // max-width: 960px;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }
    // @media (min-width: 768px) and (max-width: 800px) {
    //     padding-right: 15px;  
    //     max-width: 720px;
    // }
    // @media (min-width: 576px) and (max-width: 768px) {
    //     padding-right: 15px;  
    //     max-width: 540px;
    // }
    
}

// Select
.css-b62m3t-container, .container_select {
    width: auto !important;
}

.container_datatable_expanded {
    p {
        font-size: 14px;
        padding: 16px 0;
        margin: 0;
    }
}

.breadcrumb-item-disabled {
    pointer-events: none;
    cursor: default; 
}

.btn-outline-danger {
    svg {
        color: #dc3545 !important;
    }

    &:hover{
        svg {
            color: $white !important;
        }
    }
}

.table-custom {
    background: $background-color !important;
    color: $text_color !important;
}

.table-custom .rdt_TableHeadRow {
    background: $background-color !important;
    color: $text_color !important;
}

.table-custom .rdt_TableBody .rdt_TableRow {
    background: $background-color !important;
    color: $text_color !important;
}

.table-custom .rdt_TableRow:nth-child(even) {
    background: $surface-color !important;
}

.table-custom .rdt_TableRow:nth-child(odd) {
    background: $background-color !important;
}

.table-custom .container_datatable_expanded {
    background: $background-color !important;
    color: $text_color !important;
    padding: 10px;
}


.table-custom .rdt_Pagination {
    background: $background-color !important;
    color: $text_color !important;
    border-top: 1px solid $gray;
}

.table-custom .rdt_Pagination button {
    color: $text_color !important;
    border: none;
    border-radius: $border_radius;
    padding: 5px 10px;
    transition: background 0.3s ease;

    &:hover {
        background: $primary !important;
        color: $white !important;
    }

    &:disabled {
        color: $text_color!important;
        cursor: not-allowed;
    }
}

.table-custom .rdt_Pagination button#pagination-previous-page,
.table-custom .rdt_Pagination button#pagination-next-page,
.table-custom .rdt_Pagination button#pagination-last-page,
.table-custom .rdt_Pagination button#pagination-first-page {
    color: $text_color !important;
    fill: $text_color !important;

    &:hover {
        background: $primary !important;
        color: $white !important;
    }
}

.table-custom .rdt_Pagination button.jnZiuq {
    fill: $text_color !important;
    color: $text_color !important;
  }
  
#pagination-next-page, #pagination-previous-page,
#pagination-last-page, #pagination-first-page,
#expander-button-undefined {
    fill: $text_color !important;
    color: $text_color !important;
}

button.sc-kuACkN.eLvwbV {
    fill: $text_color !important;
    color: $text_color !important;
}

.custom-input-field {
    background: $background-color !important;
    color: $text_color !important;

    &::placeholder {
        color: $gray-2 !important;
    }
}

.custom-select-field {
    background: $background-color !important;
    color: $text_color !important;

    &::placeholder {
        color: $gray-2 !important;
    }
}

.no-data-datable {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $background-color !important;
    color: $text_color !important;
}

.custom-react-select-container,
.custom-react-select-container.css-b62m3t-container {
    .select__control,
    .css-13cymwt-control, .css-t3ipsp-control {
      background-color: var(--background-color);
      color: $text_color;
      transition: none;
    }
  
    .select__menu,
    .css-1nmdiq5-menu    {
      background-color: var(--background-color);
      color: $text_color;

      [role="option"] {
        background-color: var(--background-color);
        color: $text_color;
      }
      
      [role="option"][aria-selected="true"] {
        background-color: $primary;
        color: $text_color;
      }
      
    }
  
    .select__option {
        background-color: $primary;;
        color: $text_color;
    }
  
    .select__indicator-separator {
        background-color: var(--background-color);
        color: $text_color;
    }
  
    .select__placeholder,
    .select__single-value,
    .select__multi-value__remove {
        color: $text_color !important;
    }

    .css-b62m3t-container {
        background: $background-color;
        color: $text_color;
    }
  }

  .custom-icon {
    svg {
        color: $text-color;
    }
  }

  @media screen and (max-width: 1299px) {
    .complete-check-img {
        width: 95%;
    }
  }


  @media screen and (max-width: 799px) {
    .header.responsive {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
    }

    .header-items {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .btn-responsive {
        font-size: 14px;

        svg {
            width: 16px;
        }
    }
  }