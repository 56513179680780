@import './variables.scss';

.custom-modal .modal-content{
    background: $background-color !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    color: $text_color !important;
}

.modal-dark .btn-close {
    filter: invert(1);
  }
  
  .modal-light .btn-close {
    filter: invert(0); 
  }
  

.custom-modal-title {
    font-size: 24px;
    color: $secondary;
}

.custom-modal-header.borderless {
    border-bottom: none;
    margin-bottom: 0;
}

.custom-modal-form-item {
    margin-bottom: 10px;
}

.custom-modal-footer.borderless {
    border-top: none;
    margin-top: 0;
}

.custom-modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
