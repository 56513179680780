@import "../../style/variables.scss";

ol.breadcrumb {
    margin: 4px 0;
    display: flex;
    align-items: flex-end;
}

.breadcrumb-item {
    line-height: 18px;
    a {
        color: $gray-2;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;

        &:hover, &:active, &:focus{
            color: $secondary !important;
            text-decoration: underline;
        }
    }
}

li.breadcrumb-item.active {
    font-weight: bold;
    color: $text-color;
    text-decoration: underline;
    font-size: 14px;
}