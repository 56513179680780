@import "../../style/variables.scss";
  
.icon {
    svg {
        width: 22px;
        color: $text_color;
    }
} 

.icon_home {
    svg {
        color: $gray-2;
        width: 20px;
        &:hover, &:active, &:focus{
            color: $secondary !important;
            font-weight: bold;
        }
    }
}

.hierarchy-icon {
    svg {
        width: 32px;
    }
}