@import "../../../../style/variables.scss";

.container_form_new_user {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.open-event-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    text-align: right !important;
}