@import "../../style/variables.scss";

.container_esquecisenha_panel {
    display: flex;
    width: 450px !important;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    box-shadow: $box-shadow;
    background: $white;
    @media screen and (max-width: 767px) {
        width: 90% !important;
        
    }
}

.container_esquecisenha_panel_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.container_esquecisenha_panel_password {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    a {
        color: $secondary !important;
        font-family: Nunito;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        text-decoration-line: underline !important;
    }
}

.container_esquecisenha_panel_submit {
    width: 100%;
    button {
        width: 100%;
    }
}