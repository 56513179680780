.btn-extract {
    width: 150px !important; 
    max-width: 150px !important; 
    min-width: auto; 
    text-align: center; 
    border-radius: 5px;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  

  .btn-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
  }
  
  .btn-csv-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (max-width: 899px) {
    .btn-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;
      width: 100%;

      input.custom-input-field.form-control {
        width: 100% !important;
        min-width: 300px;
        margin: 0;
      }
    }
  }