@import "../../style/variables.scss";

.top-row-custom {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}


.custom-select {
    width: auto;
    min-width: 200px;
}

.custom-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    gap: 20px;
}

.custom-row-item {
    width: 50%;
}

.custom-row-single-item {
    width: 100% !important;
}

.custom-modal-header .custom-modal-title {
    font-size: 24px;
    color: $secondary;
}

.variable-modal {
  min-width: 35rem;
}

.expression-input-container {
  position: relative;
  margin-bottom: 1rem;

  .variable-selector {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    
    select {
      flex: 1;
    }
  }

  .var-highlight {
    color: #2b6cb0;
    font-weight: bold;
  }
}

@media screen and (max-width: 1200px) {
  .variable-modal {
    width: 80% !important;
    max-width: 80vw !important;
  }

  .top-row-custom {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    gap: 20px;
}
}

@media screen and (max-width: 580px) {
  .variable-modal {
    width: 400px !important;
    min-width: 300px;
    max-width: 400px !important;
  }
}

/* para botão de variavel calculada */
.toggle-switch {
    position: relative;
    width: 50px;
    height: 25px;
    margin: 1rem 0;
    user-select: none;
  }
  
  .toggle-checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .toggle-label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .toggle-checkbox:checked + .toggle-label {
    background: linear-gradient(to bottom, #4cd964, #5de24e);
  }
  
  .toggle-checkbox:checked + .toggle-label:after {
    transform: translateX(25px);
  }
  
  .toggle-label:hover {
    background: linear-gradient(to bottom, #c3c3c3, #f6f6f6);
  }
  
  .toggle-label:hover:after {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  }

@media screen and (max-width: 799px) { 
  .variable-page-select-div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .modal-dialog.variable-modal {
    max-width: 95% !important;
  }

  .custom-modal.new-variable-modal .modal-body .custom-modal-form.variable-new{
    display: flex;
    flex-direction: column !important;
    width: 100%;

    .custom-row {
      width: 100%;
      display: flex;
      flex-direction: column;

      .custom-row-item {
        width: 100%;
      }
    }
  }
}