.input {
    width: 100%;
}

.input_p {
    width: 100px !important;
    input {
        width: 100px !important;  
    }
}

.input_m {
    width: 200px !important;
    input, .inpu{
        width: 200px !important;  
    }
}

.input_g {
    width: 300px !important;
    input, .input {
        width: 300px !important;  
    }
}


.input_p, .input_m, .input_g {
    @media screen and (max-width: 767px) {
        width: 100% !important;

        input, .input {
            width: 100% !important;
        }
    }
}
