@import "../../style/variables.scss";

.container_navbar {
    display: flex;
    width: 100%;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $blue_dark;
    background: $background-color;

    .navbar-nav {
        width: 100%;
        justify-content: flex-end;
    }
}

.menu {
    a, span{
        color: $gray-2;
        text-decoration: none;
        margin: 0 8px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    

        &:hover, &:active, &:focus{
            color: $secondary !important;
            text-decoration: underline;
        }
    }
}

.theme-button {
    background: none;
    border: none;
    cursor: pointer;
}

.theme-button-icon {
    color: $text_color;
}

.navbar-logo-img {
    width: 180px;
}

span.btn-logout-navbar {
    background: none;
    border: none;
    cursor: pointer;
    color: $text_color !important;
    font-size: 15px;
    font-weight: 600;
    margin-left: 20px !important;
    margin-top: 5px !important;
    text-decoration: none !important;
}