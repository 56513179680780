@import "../../style/variables.scss";

.mobile-menu-trigger {
  position: fixed;
  left: 0;
  top: 15%;
  transform: translateY(-50%);
  background: $blue_dark;
  color: $white;
  padding: 12px 8px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    background: $blue_dark;
    padding-left: 12px;
  }

  @media (max-width: 768px) and (orientation: landscape) {
    top: 18%;
  }
  
  @media (max-width: 768px) and (orientation: portrait) {
    top: 10%;
  }
  
}

.mobile-menu-offcanvas {
  width: 80% !important;
  max-width: 300px;
  background: $blue_dark !important;
  color: $white;

  .offcanvas-header {
    border-bottom: 1px solid $gray_lite;
  }

  .btn-close {
    filter: invert(1);
  }

  .nav-link {
    color: $white !important;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 20px;
    border-radius: $border_radius;
    margin: 4px 0;

    svg {
      width: 22px;
    }

    &:hover {
      background: $orange_lite;
      color: $text_color !important;

      svg {
        color: $white !important;
      }
    }

    &.active_item_menu {
      background: $orange_lite !important;
      color: $black !important;

      svg {
        color: $black !important;
      }

      &:hover {
        background: $orange_lite !important;
        color: $black !important;

        svg {
          color: $black !important;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .mobile-menu-trigger {
    display: none;
  }
}

.mobile-menu-icon {
  width: 35px;
  height: 35px;
  max-width: 35px;
  margin-right: 15px;
}
