.container-panel-mov {
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

@media screen and (max-width: 699px){
    .container-panel-mov.responsive {
        display: flex;
        flex-direction: column;
    }
}