@import "../../style/variables.scss";

.container_login_panel {
    display: flex;
    width: 450px !important;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    box-shadow: $box-shadow;
    background: $background-color;
    
    @media screen and (max-width: 767px) {
        width: 90% !important;
        
    }
}

.container_login_panel_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    min-height: 300px;

    .qr-code-container,
    .code-2fa-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}

.container_login_panel_password {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    a {
        color: $secondary !important;
        font-family: Nunito;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        text-decoration-line: underline !important;
    }
}

.container_login_panel_submit {
    width: 100%;
    button {
        width: 100%;
    }
}

.qr-code-container, .code-2fa-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  /* Garante que todas as views ocupem o mesmo espaço */
  .container_login_panel_input > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .qr-code-container, .code-2fa-container {
    animation: fadeIn 0.3s ease-in;
    min-height: 300px; // Mantém altura consistente
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }

  .btn-auth {
    background: transparent !important;
    color: $primary !important;
    outline: none;
    cursor: pointer;
    border: none !important;
    font-size: 15px;
    height: 30px;

    &:hover {
        color: $blue_dark !important;
    }
  }

  .container_login_panel_logo {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 120%;
    }
  }

  .footer-login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-widtH: 899px) {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      justify-content: center;
      gap: 10px;
    }
  }

