@import "../../../../style/variables.scss";

.container_element {
    background: $background-color;
    width: 450px;
    position: absolute;
    z-index: 9999;
    height: 100vh;
    right: 0;
    top: 0;
    border-radius: 32px 0px 0px 32px;
    box-shadow: $box-shadow;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-bottom: 32px;
    overflow-y: auto;
    color: $text-color;
}

.container_element_title{
    display: flex;
    padding: 8px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid $gray;

    h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.container_element_inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    div {
        width: 100%;
    }
}

.container_create_element__button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    align-self: stretch;
}

.container_hide {
    display: none;
}

.container_create_element{
    width: 100%;
    border-bottom: 1px solid $gray;
    padding: 0px 8px 8px 8px;
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding-bottom: 25px;
    margin-top: 10px;
}

.container_create_properties {
    width: 100%;
}

djs-container {
    background-color: $background-color;
    color: $text-color;
  }
  
  .djs-canvas {
    background-color: $background-color;
  }


.djs-element.djs-connection {
    path {
      stroke: $text-color !important;
    //   fill: $text-color !important;
    }
  
    rect.djs-outline {
      fill: $text-color !important;
      stroke: pink !important;
    }
  
    path.djs-hit.djs-hit-stroke {
      stroke: $text-color !important;
    }
  
    defs marker path {
      stroke: $text-color !important;
      fill: $text-color !important;
    }
  }

  .djs-visual #StartEvent* {
    stroke: pink !important;
  }
  
  .djs-context-pad.open {
    fill: $background-color !important;
    color: $text-color !important;
    border: none !important;    
    box-shadow: none !important;  
  }

  .djs-context-pad.open .entry {
    background: $background-color !important;
    color: $text-color !important;
  }

  
  .bpmn-icon {
    fill: $text-color;
  }

  .djs-visual rect {
    fill: $background-color !important;
    stroke: $text-color !important;
    background: $background-color !important;
  }

  .djs-element.djs-shape.selected .djs-visual rect,
.djs-element.djs-shape.selected .djs-visual circle,
.djs-element.djs-shape.hover.selected .djs-visual rect,
.djs-element.djs-shape.hover.selected .djs-visual circle {
  fill: $background-color !important;   
  stroke: $text-color !important;        
  fill-opacity: 1 !important;    
  background: $background-color !important; 
}        

body.djs-cursor-grabbing {
  .djs-container, .bjs-container {
    background-color: $background-color !important;
  }
  .djs-drag-active {
    background: $background-color !important;
    .djs-visual rect,
    .djs-visual circle,
    .djs-visual path {
      fill: $background-color !important;
      stroke: $text-color !important;
      fill-opacity: 1 !important;
    }

    [style*="fill: white"] {
      fill: $background-color !important;
    }
  }
}


  
  .djs-label {
    fill: $text-color !important;
    stroke: none !important;
    text-transform: uppercase !important;
    margin: 15px !important;
  }

  .djs-direct-editing-content {
    text-transform: uppercase !important;
  }

  .djs-palette {
    background-color: $surface-color !important;
    border: none !important; 
    box-shadow: none !important;
  
    .djs-palette-entries {
      .entry {
        background: transparent !important; 
        color: $text-color !important; 
  
        svg {
          fill: $text-color !important;
        }
      }
    }
  
    .djs-palette-toggle {
      background-color: $background-color !important;
    }
  }

  .djs-direct-editing-content {
    background: $surface-color !important;
    color: $text-color !important;
  }
  
  .djs-visual react.djs-hit.djs-hit-all .djs-outline {
    stroke: pink !important;
  }

  .djs-element.djs-shape:not([data-element-id$="_label"]) > .djs-visual > circle {
    fill: $background-color !important;
    stroke: $text-color !important;
  }
  
//   .djs-overlay,
//   .djs-selection {
//     background-color: $overlay;
//   }