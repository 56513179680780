@import "../../style/variables.scss";

.custom-chart-size {
    width: 600px !important;
    height: 300px !important;
    margin: 10px;
}

.smc-data-card {
    padding: 0;
    border-radius: 10px;
    background: $background-color !important;
    color: $text_color !important;
    cursor: pointer;
    width: 350px;
    height: 160px !important;
    max-height: 160px !important;
    min-height: 160px !important;  
    margin-bottom: 10px;
    border: none !important;
    box-shadow: $box-shadow 0px 2px 8px 0px;

    &:hover {
        background: #fff;
        border-color: #00587A;
    }
}

.smc-data-card-body {
    width: 100%;
    height: 160px !important;
    max-height: 160px !important;
    min-height: 160px !important;  
    padding: 20px;
    background: $background-color !important;
    border-radius: 10px;
    color: #00587A !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    border: 2px solid #00587A;
    &:hover {
        color: #fff;
        background: #00587A;
    }
    &:active {
        color: #fff;
        background: #00587A;
    }
}


.smc-data-card-title {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  gap: 5px;

  @media screen and (max-width: 899px) {
    font-size: 16px;
  }
}

.smc-data-card-icon {
    svg {
        color: #00587A;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
    }
  }
  
  .smc-data-card-body:hover .smc-data-card-icon {
    svg {
        color: #fff;
    } 
  }



.smc-data-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .smc-data-form-body {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1px;
    padding-right: 3px;
    border-radius: 10px;
    border: 1px solid $gray-2;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    flex-wrap: wrap;
    background-color: $gray_lite;
    box-sizing: border-box;
  }
  
  .custom-radio-button {
    padding: 10px 20px;
    margin: 3px;
    border-radius: 10px;
    background-color: $background-color; 
    border: 1px solid $gray_2;
    color: #F48120;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
  
    &:hover {
      background-color: #ebebeb; 
    }
  
    &.selected {
      background: #F48120;
      color: #fff;
    }
  }

  .smc-data-form-button {
    margin-left: 15px;
    border-radius: 10px;

    @media screen and (max-width: 899px ) {
      margin: 0 !important;
    }
  }
  
  .smc-data-form-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-left: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 100px;
  }

  .smc-data-form-date.hidden {
    opacity: 0;
    max-height: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  .smc-data-form-div {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .smc-data-form-input-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .smc-data-card-container {
    width: 100%;
    display: "flex";
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-left: 5%;
    padding-right: 5%;
  }

.smc-data-card-body {
    transition: background 0.3s, color 0.3s;
}

.smc-data-card-body:hover,
.smc-data-card.active .smc-data-card-body {
    background: #007aa5 !important;
    color: #fff !important;
}

.smc-data-card.active {
    color: #fff;
    background: #00587A;
}


.charts-n-stats-container {
    width: 90%;
    min-height: 600px;
    background: $background-color !important;
    border-radius: 10px;
    border: 1px solid $gray-2 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 2%;
    margin-bottom: 30px;
}

.charts-n-stats-top {
    padding-top: 0;
}

.charts-n-stats-top-left {
  padding: 0;
}

.smc-data-title {
  font-weight: 400;
  color: #F48120;
  margin-bottom: 20px;
  margin-top: 15px;
  font-size: 22px;

  @media screen and (max-width: 899px) {
      font-size: 16px;
  }
}

// Configurando tamanho da div de gráficos
@media screen and (min-width: 1899px) {
    .charts-n-stats-container {
        width: 85%;
        max-width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 1000px;
        border-radius: 10px;
        border: 1px solid $gray-2 !important;
        margin-bottom: 30px;
    }   
}

@media screen and (max-width: 1299px) {
    .charts-n-stats-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 1000px;
        padding: 10px;
        border-radius: 10px;
        border: 2px solid rgb(218, 216, 216);
        margin-bottom: 30px;
    }   
}

.charts-n-stats-top {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    min-height: 400px;
}
    

.charts-n-stats-top-left, .charts-n-stats-top-right {
    width: 50%;
    background: $background-color !important;
    color: $text_color;
}

.charts-n-stats-btns {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.charts-n-stats-top-left {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    padding: 0;
}

.charts-n-stats-top-left {
    width: 50% !important;
    transition: width 0.3s ease;
    
    &.full-width {
      width: 100% !important;
    }
}

  .smc-datatable .rdt_Table {
    width: 100% !important;
    height: 400px;
    flex-grow: 1;
    max-height: 400px;
    background: $background-color !important;
    overflow: auto;
    border: 1px solid $gray-2 !important;
    border-radius: 10px;
  }

  .smc-datatable .rdt_TableCol {
    background: #00587A !important;
    color: white !important;
  }
  
  .smc-datatable .rdt_TableHead {
    background: #00587A;
  }
  
  .smc-datatable .rdt_TableRow {
    min-width: 100% !important;
  }

.smc-datatable::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa; 
    margin-top: -16px !important;
}
  

.smc-datatable::-webkit-scrollbar-thumb {
    background: #00587A;
    cursor: pointer;
}


.smc-histogram {
    height: 350px !important;
    width: 100%;
}

.smc-linechart {
    height: 400px !important;
    width: 100% !important;

    .chartjs-render-monitor {
        .chartjs-grid {
          line {
            stroke: rgba(0, 0, 0, 0.1);
          }
        }
        .chartjs-axis-title {
          font-weight: 600;
          color: #666;
        }
      }
}


.smc-counter {
    background: $background-color !important;
    border-radius: 10px;
    border: 2px solid #00587A;
    color: #4489a5;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;

    &:hover {
        background: #00587A !important;
        color: #fff;
    }

}

.smc-data-form-input-div.startDate {
  margin-left: 30px;
}

.smc-data-form-input-div.endDate {
  margin-right: 30px;
}

// ---- RESPONSIVIDADE ----

// Configurando layout dos graficos p/ responsividade
@media screen and (max-width: 1350px) {
    .smc-data-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        box-shadow: none;
        border: none;
      }
      
      .smc-data-form-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1px;
        padding-right: 3px;
        border: none;
        gap: 10px;
        flex-wrap: wrap;
        box-sizing: border-box;
        box-shadow: none;
        background: none;
      }
      
      .custom-radio-button {
        padding: 10px 20px;
        margin: 3px;
        border-radius: 10px;
        // background-color: #fff; 
        border: 1px solid #868686;
        color: #F48120;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-align: center;
        width: 97%;
      
        &:hover {
          background-color: #ebebeb; 
        }
      
        &.selected {
          background: #F48120;
          color: #fff;
        }
      }
    
      .smc-data-form-button {
        margin-left: 15px;
        border-radius: 10px;
        width: 97%;
      }
      
      .smc-data-form-date.visible {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-left: 10px;
        overflow: hidden;
        transition: all 0.3s ease;
        opacity: 1;
        max-height: 100px;
        width: 100%;
      }
    
      .smc-data-form-date.hidden {
        opacity: 0;
        max-height: 0;
        margin: 0;
        padding: 0;
        border: 0;
      }
      
      .smc-data-form-div {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    
    .smc-data-form-input-div span {
        display: none;
    }

    .charts-n-stats-top {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        height: auto;
    }

    .charts-n-stats-top-left, .charts-n-stats-top-right {
        width: 100% !important;
    }

    .smc-datatable.sc-fkSzgi .rdt_Table {
        width: 100% !important;
    }
    
}

// Configurando os campos de data p/ responsividae
@media screen and (max-width: 899px) {

    .header-relatorio {
       h1 {
        font-size: 19px !important;
       }
    }
    .charts-n-stats-container{
      border: none !important;
      box-shadow: none !important;
    }

    .smc-data-form-input-div {
      width: 100%;
    }
    .smc-data-form-input-div.startDate, 
    .smc-data-form-input-div.endDate {
      margin: 0 !important;
      width: 100%;
    }

    .smc-data-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .smc-data-form-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
    }

    .smc-data-form-div {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
    }

    .smc-data-card-container {
      width: 95%;
      display: "flex";
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;
      padding: 0 !important;
    }

    .smc-data-card-body.card-body {
      padding: 10px !important;
    }

}
