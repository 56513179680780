@import "../../style/variables.scss";
.container_card_flowchart {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;
    margin: 24px 0;
}


.form_container {
    z-index: 2;
}

.container_card_element.form_page_container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    align-content: center;
    gap: 32px;
    flex-wrap: wrap;
    margin: 20px 0;
    flex-direction: column;
    border-radius: 10px;
    padding: 15px;
    z-index: 3;
    h3 {
        margin-bottom: 16px;
    } 
}

.card_flowchart {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: $border-radius;
    border: 1px solid var(--gray, #E6E6E6);
    background: $gray_lite;
    position: relative;  
    max-width: 360px;

    @media screen and (min-width: 425px) {
        min-width: 330px;
    }

    h2 {
        margin: 0;
    }

    .flowchart-description {
        margin: 0;
        font-size: 14px;
        text-align: left;
    }

    .card_body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }
}

.element {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    align-items: flex-start; 
    position: relative;
    margin-bottom: 32px;

    .container_cards_element{
        margin: 0;
    }
}
.card_icon {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
}

.card-action {
    width: 100%;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        text-align: center;
        width: 100%;
    }

    button {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: flex-start;
        width: 100%;
        align-items: center;

        svg {
            width: 28px;
            height: 28px;
            color: $white;
        }
    }
}

.container_cards_elements {
    display: flex;
    gap: 16px;
    width: 100%;
    margin: 16px 0;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 16px;
}

.card_open {
    border: 1px solid $gray;
    border-left: 8px solid $green;
    background-color: $gray_lite;
    border-radius: $border-radius;
}

.container_descripiton {
    margin: 0;
    padding: 0 0 16px;

    p {
        margin: 0;
    }
}

.container_cards_elements {
    display: flex;
    padding: 0;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    margin: 0;
    max-height: 356px;
    overflow: auto;
    scroll-snap-type: x mandatory;
    //flex-wrap: nowrap;
}

.container_header_close_event {
    button {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: flex-start;
        width: auto;
        align-items: center;
    }
}

.container_header_form {
    .icon {
        svg {
            color: #fff;
        }
    }
}

.close_event_alert {
    position: absolute;
    top: -24px;
    width: 100%;
}

.header_operator {
    div {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: self-start;
        svg{
            color: $secondary;
            width: 32px;
            height: 32px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 799px) {
    .header_operator {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    h1 {
        font-size: 20px;
    }
    
}

.header_operador_form {
    display: block !important;
}

.element_properties_container {
    display: flex;
    gap: 16px;
    align-items: center;

    .element_properties_container__alert {
        cursor: pointer;
    }
}

.card_title {
    width: 100%;

    @media screen and (max-width: 799px) {
        h2 {
            font-size: 16px;
        }
    }
}

.container_card_open {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: bold;

        .icon {
            color: $text_color;
        }
    }
}

.container_card_inputs {
    display: flex;
    gap: 16px;
    align-items: center;
}

.container_rastreio_list {
    h2 {
        margin-bottom: 8px;
    }
}

.form_input {
    max-width: 50vw;
    width: 50rem;
    margin-bottom: 10px;
}

@media screen and (max-width: 1600px){
    .label_field {
        font-size: 18px !important;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .container-custom-fluid .container_triangle {
        display: none !important;
    }
}

.container-custom-fluid .container_triangle  {
    z-index: 1;
}

.label_field {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
}

.container_card_element .form_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 899px) {
    .container_card_flowchart {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .card_flowchart {
        width: 100%;
    }

    .header_operator.header-flowchart-form {
        display: flex;
        flex-direction: column !important;
        width: 100%;
        gap: 35px;
    }

    .header-flowchart-form .container_header_close_event 
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}