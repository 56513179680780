@import "../../style/variables.scss";

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $overlay;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: progress;

    .spinner {
        animation: rotate 5s linear infinite;
        margin-bottom: 8px;
    }

    p {
        color: $gray;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}