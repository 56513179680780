@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Tiro+Devanagari+Hindi:ital@0;1&display=swap');

$box-shadow: 0px 0px 5px 0px rgba(33, 33, 33, 0.15);


$white: #ffffff;
$primary: var(--primary-color);
$blue_dark: var(--blue-dark-color);
$secondary: var(--secondary-color);
$orange_lite: var(--orange-lite-color);
$gray: #E6E6E6;
$gray-1: #faf8f8;
$gray-2: #838383;
$gray_lite: rgba(230, 230, 230, 0.10);
$text_color: var(--text-color);
$green: #00A551;
$overlay: rgba(0, 0, 0, 0.7);
$red: #dc3545;
$background-color: var(--background-color);
$surface-color: var(--surface-color);
$black: #212121;
$box-shadow: var(--box-shadow);

// border
$border_radius: 4px;

//definindo variáveis e temas
:root {
    --background-color: #ffffff;
    --surface-color: #faf8f8;
    --text-color: #212121;
    --primary-color: #009ED9;
    --secondary-color: #F48120;
    --gray-color: #E6E6E6;
    --gray-color-1: #faf8f8;
    --gray-color-2: #838383;
    --gray-color-lite: rgba(230, 230, 230, 0.10);
    --overlay-color: rgba(0, 0, 0, 0.7);
    --red-color: #dc3545;
    --blue-dark-color: #00587A;
    --orange-lite-color: #FFB475;
    --success: #00A551;
    --error: #dc3545;
    --overlay: rgba(0, 0, 0, 0.7);
    --box-shadow: 0px 0px 5px 0px rgba(33, 33, 33, 0.15);
    --mw-long-select: 400px;
    --mw-mid-select: 200px;
    --mw-short-select: 150px;
}

[data-theme='dark'] {
    --background-color: #1a1a1a;
    --surface-color: #2d2d2d;
    --text-color: #ffffff;
    --primary-color: #0077a3;
    --primary-dark: #004b63;
    --secondary-color: #cc6d1b;
    --secondary-light: #ff9e4a;
    --gray-base: #4d4d4d;
    --blue-dark-color: #012431;
    --gray-dark: #b3b3b3;
    --overlay: rgba(255, 255, 255, 0.15);
    --box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.15);
  }



