@import "../../../style/variables.scss";

.container-editor {
    width: 100%;
    height: 550px;
    border-radius: 8px;
    border: solid 1px $gray;
}

.save_process {
    padding: 16px 0;
}

.container_alerts_edit {
    padding: 16px 0 0;
}

.container_editor_avisos {
    color: $red;
    font-size: 16px;
    margin: 8px 0;
    padding: 0;
}

.bpmn-label text {
    text-transform: uppercase !important;
}

.djs-label text {
    text-transform: uppercase !important;
}
