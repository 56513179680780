.container_formdata {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
}

.container_formdata_input {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    
    input {
        width: 100%;
    }
}

.formdata_title {
    label {
        font-size: 32px;
        font-weight: 800;
    }

    p {
        font-size: 16px;
        margin: 0;
    }
}

.container_formdata_actions { 
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.form_finish {
    width: 100%;

    button {
        margin: 24px 0 32px 0;
    }
}