@import "../../../../../../style/variables.scss";

.container_cards {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.card {
    border: 1px solid $gray;
    border-radius: $border_radius;
    width: 100%;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: $box-shadow;
}

.card-header {
    background-color: $surface-color;
    padding: 16px;
    border-bottom: 1px solid $gray-lite;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $text-color;
}

.card-body {
    padding: 8px 16px;
    background-color: $background-color;
    color: $text-color;
}

.card-header-actions {
    display: flex;
    flex-direction: flex;
    gap: 16px;
}

.card_edit {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.inactive-card .card-header {
    opacity: 0.5;
    border-left: 5px solid $red;
}

.toggle-switch {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 1.5rem;
    user-select: none;
}

.toggle-switch input {
    display: none;
}

.toggle-switch .slider {
    width: 50px;
    height: 26px;
    background-color: $gray;
    border-radius: 50px;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}

.toggle-switch .slider::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background-color: $white;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
}

.toggle-switch input:checked+.slider {
    background-color: $green;
}

.toggle-switch input:checked+.slider::before {
    transform: translateX(24px);
}

.toggle-label {
    font-size: 0.9rem;
    color: $text_color;
}